
import { Button, Row, Col, Divider } from 'antd';

import { HomeTwoTone, PhoneTwoTone, GlobalOutlined, MailTwoTone } from '@ant-design/icons';


export const AppFooter = () => {


  return (
    <div className='footer-container'>
      <Row justify="space-around" align="middle" className='footer-row' style={{paddingTop: "10px"}}>

        <Col span={12}>
            <HomeTwoTone twoToneColor="#f0f0f0" />
        </Col> 
        <Col span={12} className='footer-text-style'>
            home
        </Col> 
      </Row>
      <Row justify="space-around" align="middle" className='footer-row'>
      <Col span={12}>
      <MailTwoTone twoToneColor="#f0f0f0" />
        </Col> 
        <Col span={12} className='footer-text-style'>
            mail
        </Col> 
       
      </Row>
      <Row justify="space-around" align="middle" className='footer-row'>
      <Col span={12}>
      <PhoneTwoTone twoToneColor="#f0f0f0" />
        </Col> 
        <Col span={12} className='footer-text-style'>
            phone
        </Col> 
        
      </Row>
      <Row justify="space-around" align="middle" className='footer-row' style={{paddingBottom: "10px"}}>
      <Col span={12}>
      <GlobalOutlined style={{color:'#F0F0F0'}}/>
        </Col> 
        <Col span={12} className='footer-text-style'>
            web
        </Col> 
      </Row>

      
        
        
    </div>
  );
}
