import './App.css';

import 'antd/dist/antd.css';

import { BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';

import { AppHeader } from './components/AppHeader.js';
import { AppFooter } from './components/AppFooter.js';
import { AboutPage } from './pages/AboutPage.js';
import { HomePage } from './pages/HomePage.js';
import { NotFoundPage } from './pages/NotFoundPage.js'
import { AppSocial } from './components/AppSocial';

import {HotTubPage} from './pages/HotTubPage.js';
import { AcommodationPage } from './pages/AcommodationPage';

function App() {
  return (
    <div className="App">
      <Router>
        <AppHeader />
        <Switch>
          <Route path='/' exact>
            <HomePage />
          </Route>
          <Route path='/aboutus'>
            <AboutPage />
          </Route>
          <Route path='/hottub'>
            <HotTubPage />
          </Route>
          <Route path='/acommodations'>
             <AcommodationPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
        <div style={{height: '20px'}} />
        <AppFooter />
        <AppSocial />
      </Router>
    </div>
  );
}

export default App;
