import { Link } from 'react-router-dom';
import { Menu, Typography } from 'antd';
import { Spring } from 'react-spring/renderprops';
const { Title } = Typography;

export const AppHeader = () => {
  return (
    <Spring
            from = {{ opacity: 0, }}
            to = {{ opacity: 1 }}
            config = {{ delay: 0, duration: 500 }}
    >
        {props => (
            <div style={props}>
                <div className='header-container'>
                    <Menu mode='horizontal'>
                        <Menu.Item key="1"><Link to='/'>Home</Link></Menu.Item>
                        <Menu.Item key="2"><Link to='/aboutus'>About Us</Link></Menu.Item>
                        <Menu.Item key="3"><Link to='/acommodations'>Acommodations</Link></Menu.Item>
                        <Menu.Item key="4"><Link to='/hottub'>Hot Tub</Link></Menu.Item>
                    </Menu>
                </div>
            </div>
        )}
    </Spring>
  );
}
