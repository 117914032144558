import { Button, Row, Col, Image, Space, Carousel } from 'antd';

import { Spring } from 'react-spring/renderprops';
import Table from '../images/table.png';

import Pool1 from '../images/pool1.jpeg';
import Pool2 from '../images/pool2.jpeg';
import Pool3 from '../images/pool3.jpeg';
import Pool4 from '../images/pool4.jpeg';
import Pool5 from '../images/pool5.jpeg';


export const HotTubPage  = () => {
  return (
    <>
        <div>
            <h3 style={{paddingTop: '20px', paddingBottom: '20px', width: '50%', margin: 'auto'}}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
            </h3>
        </div>
        <Carousel autoplay style={{width: "100%"}}>
            <Image
                src={Pool1}
            />
            <Image
                src={Pool3}
                />
               
                <Image
                src={Pool5}
                />
            
        </Carousel>  
        <div>
            <h3 style={{paddingTop: '20px', paddingBottom: '20px', width: '50%', margin: 'auto'}}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </h3>
        </div> 

    </>
  );
}
