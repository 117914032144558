
import SocialButtonsContainer from 'react-social-media-buttons';


export const AppSocial = () => {
    return (
        <div className='social-container'>
            <div className='social-button-container'>
                <SocialButtonsContainer
                        links={[
                            'https://www.facebook.com/ikhulaanshivert',
                            'https://www.instagram.com/ikhulaan_touristcamp/',
                        ]}
                        buttonStyle={{
                            width: '30px', 
                            height: '30px', 
                            marginLeft: '5px',
                            marginRight: '5px',
                            backgroundColor: '#7AA8A8', 
                            borderRadius: '30%',
                        }}
                        iconStyle={{ color: '#fff' }}
                        openNewTab={ true }
                />
               
            </div>
        </div>
    );
}