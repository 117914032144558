

import { Button, Row, Col, Divider, notification } from 'antd';
import night from '../images/night.png';
import asa from '../images/asa.jpeg';



import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from "react-visibility-sensor";

export const HomePage = () => {
  const DemoBox = props => <p className={`height-${props.value}`}>{props.children}</p>;

  const openNotif = type => {
    notification[type]({
      message: 'Амжилттай',
      description:
        'Амжилттай',
    });
  };

  return (
    <>
        <img src={night} className='image-container' />
        <Divider orientation="left"></Divider>
        <iframe width="100%" height="314" style={{padding: "15px"}} src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fikhulaanshivert%2Fvideos%2F2350028878583197%2F&show_text=false&t=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <Divider orientation="left"></Divider>
        <img src={asa} className='image-container' />

        <p style={{paddingLeft: "40px", paddingRight:"40px", paddingTop:"20px"}}>
            Асашёрюү Дагвадорж, Ама Бямбядорж нар «Их улаан» жуулчны баазыг Вертолетоор зорьж ирлээ. 
        </p>
        <Divider orientation="left"></Divider>
    </>
  );
}
const h2Styles = {
    fontSize: "82px",
  };
