import { Button, Row, Col, Image, Space, Carousel } from 'antd';

import { Spring } from 'react-spring/renderprops';
import Table from '../images/table.png';

import Pool2 from '../images/pool2.jpeg';

import Entertain2 from '../images/entertain2.jpeg';
import Entertain3 from '../images/entertain3.jpeg';
import Night from '../images/night.png';
import Ger from '../images/ger1.jpeg';

import House1 from '../images/house1.jpeg';
import House2 from '../images/house2.jpeg';


import { List, Typography } from 'antd';


import VisibilitySensor from "react-visibility-sensor";

const bigHouse = [
    '2 давхар',
    '4 хүний багтаамжтай',
    'Халуун рашааны үнэ багтсан'
];

const smallHouse = [
    '1 давхар',
    '4 хүний багтаамжтай',
    'Халуун рашааны үнэ багтсан',
];

const ger = [
    'шижээ гурав',
    'шижээ гурав',
    'шижээ гурав',
];


export const AcommodationPage = () => {
  return (
    <div style={{width: '100%'}}>
        <div className='text-container'>
            <h3 style={{paddingTop: '20px', paddingBottom: '20px'}}>

            </h3>
        </div>

        <Row justify="start">
            <Col span={12} style={{textAlign: 'center', margin: 'auto'}}>
                <img src={House1} className='image-container' />
            </Col>
            <Col span={8} style={{textAlign: 'center', margin: 'auto'}}>
                <List
                    header = {
                        <div>Том байшин</div>
                    }
                    
                    dataSource={ bigHouse }
                    renderItem={ item => (
                        <List.Item className='text-style'> {item} </List.Item>
                    )}
                />                
            </Col>
        </Row>
        <div style={{height: "20pt"}}>
        </div>
        
        <Row>
            <Col span={8} style={{textAlign: 'center', margin: 'auto'}}>
                <List
                    header = {
                        <div>Жижиг байшин</div>
                    }
                    
                    dataSource={ smallHouse }
                    renderItem={ item => (
                        <List.Item className='text-style'> {item} </List.Item>
                    )}
                />                
            </Col>
            <Col span={12} style={{textAlign: 'center', margin: 'auto'}}>
                <img src={House2} className='image-container' />
            </Col>
        </Row>

        
    </div>
  );
}
