import { Button, Row, Col, Image, Space, Carousel } from 'antd';

import { Spring } from 'react-spring/renderprops';
import Table from '../images/table.png';

import Pool2 from '../images/pool2.jpeg';

import Entertain2 from '../images/entertain2.jpeg';
import Entertain3 from '../images/entertain3.jpeg';
import Night from '../images/night.png';
import Ger from '../images/ger1.jpeg';

import { List, Typography } from 'antd';
import camp from '../images/camp.jpeg';

import VisibilitySensor from "react-visibility-sensor";

const data = [
    'Racing car sprays burning fuel into crowd.',
    'Japanese princess to wed commoner.',
    'Australian walks 100km after outback crash.',
    'Man charged over missing wedding girl.',
    'Los Angeles battles huge wildfires.',
  ];

  const data2 = [
    'Australian walks 100km after outback crash.',
    'Man charged over missing wedding girl.',
    'Los Angeles battles huge wildfires.',
  ];

export const AboutPage = () => {
  return (
    <div style={{width: '100%'}}>
    <div>
        <h3 style={{paddingTop: '20px', paddingBottom: '20px', width: '50%', margin: 'auto'}}>
            Архангай аймгийн Батцэнгэл сумд байрлах Их улаан шивэрт жуулчны бааз нь хотоос 520 км - ийн зайд байрлах халуун рашаантай бүхий тав тухтай бааз юм.
            10 жилийн турш тасралтгүй үйл ажиллагаа явуулж буй 
            “Их улаан” амралтын бааз нь Архангай аймаг, Батцэнгэл суманд байралдаг. Хотоос засмал замаар 460 км яваад, Цэнхэрийн гүүрнээс салаад 28 км шороон замаар явна.
        </h3>
    </div>

    <img src={camp} className='image-container' />
   
    <div className='text-container'>
        <h3 style={{paddingTop: '20px', paddingBottom: '20px', width: '50%', margin: 'auto'}}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </h3>
    </div>
    </div>
  );
}
